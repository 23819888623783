import { ResetCSS } from '@pancakeswap/uikit'
// import { Analytics } from '@vercel/analytics/react'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import useEagerConnectMP from 'hooks/useEagerConnect.bmp'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import useThemeCookie from 'hooks/useThemeCookie'
import type { AppProps } from 'next/app'
import { Modal, Button } from 'antd'
import axios from 'axios'
import Head from 'next/head'
import React, { Fragment, useState } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { NetworkModal } from 'components/NetworkModal'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import { Blocklist, Updaters } from '..'
import { SentryErrorBoundary } from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
import 'antd/dist/antd.css'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  useThemeCookie()
  return null
}

function MPGlobalHooks() {
  usePollBlockNumber()
  useEagerConnectMP()
  usePollCoreFarmData()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  return null
}

function MyApp(props: AppProps) {
  const { pageProps, Component } = props
  const store = useStore(pageProps.initialReduxState)

  return (
    <>
      <Head>
        <meta
          name="description"
          content="GTokenTool是功能最全的一键发币工具，支持TON、SOLANA、BSC、ETH等公链一键发行代币，主营：创建代币、市值管理、批量空投、代币预售、IDO、锁、质押挖矿等。G TOKEN 官网是有2年经验的专业发币大平台，拥有活跃的官方社区和专人客服。"
        />
        <meta
          name="keywords"
          content="GTokenTool,一键发币,发币工具,官网,ton,sol一键发币,市值管理,批量空投,批量创建钱包,发币平台"
        />
        <meta name="theme-color" content="#1FC7D4" />
        <link rel="canonical" href="https://www.gtokentool.com" />
        <link rel="stylesheet" href="dist/css/swiper-bundle.min.css"></link>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <title>GTokenTool行业领先的一键发币平台</title>
        {(Component as NextPageWithLayout).mp && (
          // eslint-disable-next-line @next/next/no-sync-scripts
          <script src="https://public.bnbstatic.com/static/js/mp-webview-sdk/webview-v1.0.0.min.js" id="mp-webview" />
        )}
      </Head>
      <Providers store={store}>
        <Blocklist>
          {(Component as NextPageWithLayout).mp ? <MPGlobalHooks /> : <GlobalHooks />}
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <Updaters />
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      <script
        defer
        src="https://static.cloudflareinsights.com/beacon.min.js"
        data-cf-beacon='{"token": "73590d6b4337430a8cd13aab6ba02ba2"}'
      ></script>
      <script src="lazysizes.min.js" async></script>
      <script src="lazyload.js"></script>
      <script src="dist/js/swiper-bundle.min.js"></script>
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0"
      ></script>
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0"
        nonce="yourNonceValue"
      ></script>
      <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>
  /** render component without all layouts */
  pure?: true
  /** is mini program */
  mp?: boolean
  /**
   * allow chain per page, empty array bypass chain block modal
   * @default [ChainId.BSC]
   * */
  chains?: number[]
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (Component.pure) {
    return <Component {...pageProps} />
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [gonggao, setGonggao] = useState('')
  const [qingqiu, setQingqiu] = useState(true)
  const showModal = () => {
    setIsModalOpen(true)
  }
  const { noscriptContent } = pageProps

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  // if (qingqiu) {
  //   try {
  //     axios.get('https://pink.yagong.xyz/gonggao').then((res) => {
  //       console.log(res.data[1])
  //       const { title,  } = res.data[1]
  //       if (gonggao == "") return
  //       if (title) {
  //         setTitle(title)
  //         setGonggao(gonggao)
  //         showModal()
  //         setQingqiu(false)
  //       }
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  const ShowMenu = Component.mp ? Fragment : Menu

  return (
    <ProductionErrorBoundary>
      <ShowMenu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ShowMenu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
      <NetworkModal pageSupportedChains={Component.chains} />
      <Modal title={title} centered open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
        <p style={{ marginBottom: '12px', whiteSpace: 'pre-wrap' }}>{gonggao}</p>

        <Button type="primary" block onClick={handleOk} shape="round" size="large">
          Enter
        </Button>
      </Modal>
      {/*<Analytics />*/}
    </ProductionErrorBoundary>
  )
}

export default MyApp
