import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE_MAINNET = new Token(
  ChainId.BSC,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin')

export const USDC_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ARB = new Token(
  ChainId.ARB,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_CORE = new Token(
  ChainId.CORE,
  '0x9Ebab27608bD64AFf36f027049aECC69102a0D1e',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_OKB = new Token(
    ChainId.OKB,
    '0x1E4a5963aBFD975d8c9021ce480b42188849D41d',
    18,
    'USDT',
    'USDT',
    'https://www.paxos.com/busd/',
)
export const BUSD_BSC = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x8516Fc284AEEaa0374E66037BD2309349FF728eA',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_CORE = new Token(
  ChainId.CORE,
  '0x900101d06a7426441ae63e9ab3b9b0f63be145f1',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_BASE = new Token(
  ChainId.BASE,
  '0xF0981Ac7cf8aE865c0960347A32f6d46bf269b30',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_opBNB = new Token(
  ChainId.opBNB,
  '0xF0981Ac7cf8aE865c0960347A32f6d46bf269b30',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)


export const BUSD_OKB = new Token(
    ChainId.OKB,
    '0x1E4a5963aBFD975d8c9021ce480b42188849D41d',
    18,
    'USDT',
    'USDT',
    'https://www.paxos.com/busd/',
)
export const BUSD_APE = new Token(
  ChainId.APE,
  '0xa2235d059f80e176d931ef76b6c51953eb3fbef4',
  18,
  'USDT',
  'USDT',
  'https://www.paxos.com/busd/',
)


export const USDT_APE = new Token(
  ChainId.APE,
  '0xa2235d059f80e176d931ef76b6c51953eb3fbef4',
  18,
  'USDT',
  'USDT',
  'https://www.paxos.com/busd/',
)


export const BUSD_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0x9308AC4052ab225293121A57d2aed6eDcA2421DF',
  18,
  'USDT',
  'USDT',
  'https://www.paxos.com/busd/',
)



export const USDT_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0x9308AC4052ab225293121A57d2aed6eDcA2421DF',
  18,
  'USDT',
  'USDT',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.RINKEBY]: BUSD_RINKEBY,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.AVALANCHE]: BUSD_BSC,
  [ChainId.POLYGON]: BUSD_BSC,
  [ChainId.OPTIMISM]: BUSD_BSC,
  [ChainId.HECO]: BUSD_BSC,
  [ChainId.DOGECHAIN]: BUSD_BSC,
  [ChainId.OKXCHAIN]: BUSD_BSC,
  [ChainId.MBKCHAIN]: BUSD_BSC,
  [ChainId.ETCCHAIN]: BUSD_BSC,
  [ChainId.ETHW]: BUSD_BSC,
  [ChainId.ETF]: BUSD_BSC,
  [ChainId.ARB]: USDT_ARB,
  [ChainId.CORE]: BUSD_CORE,
  [ChainId.BASE]: BUSD_BASE,
  [ChainId.opBNB]: BUSD_opBNB,
  [ChainId.PEGO]: BUSD_BSC,
  [ChainId.OKB]: BUSD_OKB,
  [ChainId.APE]: BUSD_APE,
  [ChainId.SEPOLIA]: BUSD_SEPOLIA,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.GOERLI]: USDC_GOERLI,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.ARB]: USDT_ARB,
  [ChainId.CORE]: USDT_CORE,
  [ChainId.OKB]: USDT_OKB,
  [ChainId.APE]: USDT_APE,
  [ChainId.SEPOLIA]: USDT_SEPOLIA,
}
