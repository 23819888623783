import { useMemo } from 'react'
import { menuStatus } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import { useTranslation } from '@pancakeswap/localization'
import { useMenuItemsStatus } from './useMenuItemsStatus'
import config, { ConfigMenuItemsType, nftConfig } from '../config/config'

export const useMenuItems = (): ConfigMenuItemsType[] => {
  const router = useRouter()
  const {
    t,
    currentLanguage: { code: languageCode },
  } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const { isDark } = useTheme()
  const menuItemsStatus = useMenuItemsStatus()

  const menuItems = useMemo(() => {
    if (router.pathname === '/tokencreatenft/[id]') return nftConfig(t, isDark, languageCode, chainId)
    return config(t, isDark, languageCode, chainId)
  }, [t, isDark, languageCode, chainId, router.pathname])

  return useMemo(() => {
    if (menuItemsStatus && Object.keys(menuItemsStatus).length) {
      return menuItems.map((item) => {
        const innerItems = item.items.map((innerItem) => {
          const itemStatus = menuItemsStatus[innerItem.href]

          if (itemStatus) {
            let itemMenuStatus
            if (itemStatus === 'soon') {
              itemMenuStatus = menuStatus.SOON
            } else if (itemStatus === 'live') {
              itemMenuStatus = menuStatus.LIVE
            } else if (typeof itemStatus === 'function') {
              itemMenuStatus = itemStatus()
            } else {
              itemMenuStatus = menuStatus.NEW
            }

            return { ...innerItem, status: itemMenuStatus }
          }
          return innerItem
        })
        return { ...item, items: innerItems }
      })
    }

    return menuItems
  }, [menuItems, menuItemsStatus])
}
